import React from 'react'

const Footer = (props) => {
    return (
        <div>
            Copyright 2022 by Auradine Inc.
        </div>
    )
}

export default Footer