import React from 'react'
import logo from '../assets/logo.png'
import { Box, Typography, Stack } from '@mui/material';

const Header = () => {
    return (
        <Stack direction='row' sx={{ alignItems: 'center' }}>
            <img src={logo} className="logo" alt="logo" />
            <Typography variant='h2' sx={{ ml: 10 }}>Software Updates</Typography>
        </Stack>
    )
}

export default Header