import React from 'react'
import { Box, Typography, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const jsondata = require('../assets/software.json')

const PageSize = 25

const renderDownloadLink = (params) => {
    // console.log(params)
    if (params.value) {
        return <a href={"software/" + params.value} download>{params.value}</a>
    }
    return ""
}

const renderNotes = (params) => {
    // console.log(params)
    if (params.value) {
        const text = params.value
        return (
            <Box sx={{ width: '100%', borderRadius: 1, border: '1px solid grey', p: 2 }}>
                <Typography variant="body1" gutterBottom>
                    {text}
                </Typography>
            </Box>
        );
    }
    return ""
}

const columns = [
    { field: 'name', headerName: 'Name', minWidth: 280, renderCell: renderDownloadLink },
    { field: 'version', headerName: 'Version', minWidth: 180 },
    { field: 'os', headerName: 'OS', minWidth: 180 },
    // { field: 'size', headerName: 'Size', minWidth: 50, },
    // { field: 'created', headerName: 'Created', minWidth: 220 },
    { field: 'sha256', headerName: 'SHA256', minWidth: 550 },
    { field: 'notes', headerName: 'Release Notes', minWidth: 400, flex: 1, renderCell: renderNotes, },
];


const Tools = () => {

    const [, setPage] = React.useState(1);
    const [rows] = React.useState(jsondata.images);

    return (
        <Box sx={{ m: 10 }}>
            <Typography variant='h3'>Software</Typography>
            <Paper sx={{ mt: 10, p: 5 }} elevation={3}>
                <Typography variant='body1' >[1]: macOS users: remove downloaded file quarantine flag from the terminal: xattr -r -d com.apple.quarantine *.dmg</Typography>
            </Paper>
            <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                getRowHeight={() => 'auto'}
                getRowId={(row) => row.name}
                pagination
                pageSize={PageSize}
                rowsPerPageOptions={[PageSize]}
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
            />
        </Box >
    )
}

export default Tools