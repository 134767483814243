import React from 'react'
import { Box, Typography, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const jsondata = require('../assets/images.json')

const PageSize = 25

const renderDownloadLink = (params) => {
    // console.log(params)
    if (params.value) {
        return <a href={"images/" + params.value} download>{params.value}</a>
    }
    return ""
}

const renderNotes = (params) => {
    // console.log(params)
    /*
    if (params.value) {
        const text = params.value
        return (
            <Box sx={{ width: '100%', borderRadius: 1, border: '1px solid grey', p: 2 }}>
                <Typography variant="body1" gutterBottom>
                    {text}
                </Typography>
            </Box>
        );
    }
    return ""*/
    if (params.value) {
        return <a href={"notes/" + params.value} download>{params.value}</a>
    }
    return ""
}

const columns = [
    { field: 'name', headerName: 'Name', minWidth: 280, renderCell: renderDownloadLink },
    { field: 'version', headerName: 'Version', minWidth: 180 },
    { field: 'model', headerName: 'Model', minWidth: 50 },
    { field: 'size', headerName: 'Size', minWidth: 50, },
    { field: 'latest', headerName: 'Latest', minWidth: 50 },
    { field: 'created', headerName: 'Created', minWidth: 220 },
    { field: 'sha256', headerName: 'SHA256', minWidth: 550 },
    { field: 'notes', headerName: 'Release Notes', minWidth: 400, flex: 1, renderCell: renderNotes, },
];

const sortModel = [
    {
        field: 'created', // the name of the field to sort by
        sort: 'desc', // 'desc' or 'asc'
    },
];

const IsLatest = (ver, latestVer) => {
    if (ver === latestVer) {
        return "Yes"
    }
    return ""
}

const Images = () => {

    const imageArray = jsondata.images

    imageArray.forEach((item) => {
        if (IsLatest(item.name, jsondata.latest)) {
            item.latest = 'Yes'
        }
    });

    const [, setPage] = React.useState(1);
    const [rows] = React.useState(jsondata.images);

    return (
        <Box sx={{ m: 10 }}>
            <Typography variant='h3'>MicroSD Images</Typography>
            <Paper sx={{ mt: 10, p: 5 }} elevation={3}>
                <Typography variant='body1' >Flash an SD Card by dragging entire .zip file into Balena/Etcher <br /> or unzip file and use bmaptool: bmaptool copy --bmap image/.meta/[file].bmap image/[file].img /dev/[mmcblk0]</Typography>
            </Paper>
            <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                sortModel={sortModel} // add this line to specify the sort model
                getRowHeight={() => 'auto'}
                getRowId={(row) => row.name}
                pagination
                pageSize={PageSize}
                rowsPerPageOptions={[PageSize]}
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
            />
        </Box>
    )
}

export default Images
